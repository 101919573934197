import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
// import axios from 'axios';
// import { apiUrls } from '../../config';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import * as yup from 'yup';
import CustomSelect from '../.././components/CastomSelect/CastomSelect'
import { maxOrderCost, pb_key } from "../../config";
import {
  formatter, getDefaultPoint, loadUserCookie, phoneRegExp, saveDeliveryCookie, formatPhoneNumber
} from '../../helpers/utils';
import {
  checkQR,
  clearCart, clearSavedInOtherDateCart, loadPoints, moveItemToInOtherDate, registerQR, removeItem, removeUnactive, sendOrder
} from '../../redux/modules/cart';
import { useMediaQuery } from 'usehooks-ts';

import CartItem from './CartItem/CartItem';

import * as Sentry from '@sentry/react';


import styles from './Cart.module.scss';
import Closed from '../../assets/images/close.png';
import DatesFilter from '../Menu/DatesFilter/DatesFilter';
import PublicOferta from '../../assets/documents/PublicOferta.pdf'
import PoliticConfidentiality from '../../assets/documents/PoliticConfidentiality.pdf'

const initFormValue = {
  ...loadUserCookie(),
  point_id: getDefaultPoint().value,
  sel_index: getDefaultPoint().index,
  is_payment: false,
  is_payment_sbp: false,
};

const Cart = ({ active, setActive }) => {
  const dispatch = useDispatch();
  const {
    items, cartCount, pointsList,
  } = useSelector(state => state.cart);
  const { items: menu, percent, activeDate } = useSelector(state => state.menu);
  const [orderSent, setOrderSent] = useState(false);
  const [orderSentInfo, setOrderSentInfo] = useState();
  const [isPaymentOnline, setIsPaymentOnline] = useState(false);
  const [isPaymentOnlineSuccess, setIsPaymentOnlineSuccess] = useState(true);
  const [isPaymentOnlineSBP, setIsPaymentOnlineSBP] = useState(false);
  const [isPaymentOnlineSuccessSBP, setIsPaymentOnlineSuccessSBP] = useState(true);
  const [qrInfo, setQrInfo] = useState();
  const [closeTimer, setCloseTimer] = useState(false);

  const screen = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (typeof items !== 'object') {
      dispatch(clearCart());
      dispatch(clearSavedInOtherDateCart());
    }
  }, []);

  useEffect(() => {
    // console.log(qrInfo);
    const interval = setInterval(async () => {
      if (qrInfo) {
        console.log('Check payment status for '+qrInfo.Data.qrcId);
        const result = await dispatch(checkQR(qrInfo.Data.qrcId));
        if (result=='Accepted') {
          console.log("success")
          setQrInfo(false)
          setOrderSent(true)
          hundleCartRemove()
        }
        if (result=='Rejected') {
          console.log("fail")
          setQrInfo(false)
          showErrorPay()
        }
      }
    }, 3000);    
    return () => clearInterval(interval);     
  }, [qrInfo]);

  // useEffect(() => {
  //   dispatch(removeUnactive(activeDate));
  // }, [activeDate])

  if (!menu.length) { return <></>; }
  const calculateCartTotal = () => {
    let localTotal = 0;
    items.forEach(cartItem => {
      if (cartItem.date == activeDate && menu.find(item => item.good_id == cartItem.menu_item)) {
        localTotal += menu.find(item => item.good_id == cartItem.menu_item).good_price * cartItem.quantity;
      }
    })
    return localTotal;
  };

  const calculateCartTotalWithDiscount = () => {
    const cartTotal = calculateCartTotal();
    const discountAmount = cartTotal * (percent / 100);
    const totalWithDiscount = cartTotal - discountAmount;
    return totalWithDiscount;
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required('Введите имя'),
    email: yup.string().email('Введите корректный email').required('Введите email'),
    phone: yup.string().matches(phoneRegExp, 'Введите корректный телефон').required('Введите телефон'),
    point_id: yup.number().oneOf(pointsList.map(point => point.id)).required('Выберите пункт доставки')
  });

  const removeCartItem = (goodId, price) => {
    dispatch(removeItem(goodId, price));
  };

  const moveToOtherDate = (goodId, price) => {
    dispatch(moveItemToInOtherDate(goodId, price));
  };

  const handlePointChange = ({ value, index }, handleChange) => {
    saveDeliveryCookie({ value, index });

    const event = {
      persist: () => { },
      target: {
        type: 'change',
        value,
        name: 'point_id'
      }
    };
    handleChange(event);
  };

  const showErrorNotification = () => toast.error('Некоторые товары из корзины не доступны для заказа в выбранную дату', {
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  });

  const showErrorPay = () => toast.error('К сожалению, прямо сейчас предоплата невозможна из-за технической ошибки. Пожалуйста, пересоздайте заказ из корзины и оплатите на месте, когда будете забирать. Приносим извинения за неудобство.', {
    duration: 6000,
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  });

/*  
  function pay(order) {
    const widget = new cp.CloudPayments();
    setIsPaymentOnlineSuccess(true);

    widget.pay('charge', // или 'auth'
      { //options
        publicId: pb_key, //id из личного кабинета
        description: 'Оплата товаров в order.reatme.ru', //назначение
        amount: calculateCartTotalWithDiscount() / 100, //сумма
        currency: 'RUB', //валюта
        autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
        invoiceId: order.id, //номер заказа  (необязательно)
        email: order.email, //email плательщика (необязательно)
      },
    ).then(async (result) => {
      // await axios.post(apiUrls.pay, {
      //   id: order.id,
      //   guid: order.guid,
      //   transaction_id: result.data.transactionId
      // }, getRequestConfig());
      // setOrderSent(true);
      if (result.type == 'cancel') {
        setIsPaymentOnlineSuccess(false);
      }
      hundleCartRemove();
    }).catch((error) => {
      // Обработка ошибки при вызове метода widget.pay
      Sentry.captureException(error);
      setIsPaymentOnlineSuccess(false);
      showErrorPay();
      hundleCartRemove();
    });
  }
*/

  const drawQR = (qr) => {
    return (
      screen
        ? <div className={styles.cart_modal2}>
          <button className={styles.btn_modal2} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setQrInfo(false); setIsPaymentOnlineSBP(false); }}>
            <img src={Closed} alt="exit" />
          </button>
          <div className={styles.wrapper2}>
            <a href={`${qr.Data.payload}`} target='_blank'>Перейдите по ссылке для оплаты заказа</a>
          </div>
        </div>
        : <div className={styles.cart_modal2}>
          <button className={styles.btn_modal2} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setQrInfo(false); setIsPaymentOnlineSBP(false); }}>
            <img src={Closed} alt="exit" />
          </button>
          <div className={styles.wrapper2}>
            <h1>Отсканируйте QR-код для оплаты</h1>
            <img src={`data:${qr.Data.image.mediaType};base64,${qr.Data.image.content}`} />
          </div>
        </div>
    )
  };

  function pay_sbp(order) {
    try {
      setIsPaymentOnlineSuccessSBP(true);

      // вызвать СБП с point_id и id
      dispatch(registerQR(order.point_id, order.id, calculateCartTotalWithDiscount())).then((result) => {
        setQrInfo(result)
      });

    } catch (e) {
      errorNotify(e.message);
    }
  }


  const handlePay = (handleChange) => {
    const event = {
      persist: () => { },
      target: {
        type: 'change',
        value: true,
        name: 'is_payment'
      }
    };
    handleChange(event);
    setIsPaymentOnline(true);
  };

  const handlePaySBP = (handleChange) => {
    const event = {
      persist: () => { },
      target: {
        type: 'change',
        value: true,
        name: 'is_payment_sbp'
      }
    };
    handleChange(event);
    setIsPaymentOnlineSBP(true);
  };

  const hundleCartRemove = () => {
    setOrderSent(true);
    setIsPaymentOnlineSBP(false);
    dispatch(clearCart());
    let timeout = setTimeout(() => {
      if (active == true) {
        setActive(false);
        setOrderSent(false);
        setOrderSentInfo(undefined);
      }
    }, 15000);
    setCloseTimer(timeout);
  }

  const selectOptions = pointsList.map((point, index) => ({
    value: point.id,
    label: point.address,
    name: point.name,
    index
  }));

  const errorNotify = e => toast.error(e, {
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff'
    }
  });

  const formSubmit = async (values) => {
    try {
      if (!isPaymentOnline && !isPaymentOnlineSBP && Number(calculateCartTotal() / 100) > maxOrderCost) {
        errorNotify(`Максимальная сумма заказа без предоплаты - ${formatter.format(maxOrderCost)}`);
        return;
      }
      if (items.filter(item => item.date != activeDate).length > 0) {
        errorNotify(`Дата доставки и меню изменились, Вам необходимо выбрать товары заново`);
        return;
      }
      setIsPaymentOnlineSuccess(true);
      const phone = formatPhoneNumber(values.phone)
      const result = await dispatch(sendOrder({ ...values, phone }));
      values.items = items;
      setOrderSentInfo(values);
      if (result) {
        if (values.is_payment) {
          // pay({ ...result.data, ...values });
          values.is_payment = false;
        } else if (values.is_payment_sbp) {
          pay_sbp({ ...result.data, ...values });
          values.is_payment_sbp = false;
        } else {
          hundleCartRemove()
        }
      } else {
        throw new Error('Ошибка отправки заказа');
      }
    } catch (e) {
      errorNotify(e.message);
      Sentry.captureException(e)
    }
  };

  const renderOrderSent = () => {
    if (orderSent) {
      if (isPaymentOnlineSuccess) {
        return (
          <div className={styles.orderSent}>
            <h1>Спасибо! Ваш онлайн-заказ отправлен.</h1>
            <p>Адрес доставки: {pointsList.find(point => point.id == orderSentInfo.point_id).address}</p>
            <p>Вы заказали на дату: {activeDate.split('-')[2]}.{activeDate.split('-')[1]}</p>
            <hr />
            <p>Список товаров</p>
            <ul>
              {orderSentInfo.items.map((item, index) => (
                <li key={index}>{index + 1}. {menu[item.index].good_name}</li>
              ))}
            </ul>
          </div>
        );
      }
      return (
        <div className={styles.orderSent}>
          <h1>Ваш заказ не был оформлен из-за ошибки оплаты.</h1>
          <p>Пожалуйста, создайте заказ заново</p>
        </div>
      );
    }
  };
  /*
    {orderSent && isPaymentOnlineSuccess && <div className={styles.orderSent}>
      <h1>Спасибо! Ваш онлайн-заказ отправлен.</h1>
      <p>Адрес доставки: {pointsList.find(point => point.id == orderSentInfo.point_id).address}</p>
      <p>Вы заказали на дату: {activeDate.split('-')[2]}.{activeDate.split('-')[1]}</p>
      <hr />
      <p>Список товаров</p>
      <ul>
        {orderSentInfo.items.map((item, index) => (
          <li key={index}>{index + 1}. {menu[item.index].good_name}</li>
        ))}
      </ul>
    </div>}
  </>);*/

  return (
    <div className={cn(styles.cart_modal, { [styles.active]: !active })}>
      <button className={styles.btn_modal} onClick={(e) => { 
        e.stopPropagation(); 
        e.preventDefault(); 
        setActive(); 
        setOrderSent(false); 
        setOrderSentInfo(undefined);
        if (closeTimer) { 
          clearTimeout(closeTimer);
          setCloseTimer(null)
        }
      }}>
        <img src={Closed} alt="exit" />
      </button>
      <div className={styles.wrapper}>
        {
          qrInfo
            ? drawQR(qrInfo)
            : <></>
        }
        {renderOrderSent()}
        <h1>Даты для доставки <DatesFilter /></h1>
        <hr />
        <ul className={styles.list}>
          {items.map(cartItem => (
            <li key={`cartitem-${cartItem.menu_item}`} className={styles.card}>
              <CartItem
                cartItem={cartItem}
                menuItem={menu[cartItem.index]}
                removeCartItem={removeCartItem}
                inOtherDate={activeDate !== cartItem.date}
                moveToOtherDate={moveToOtherDate}
                showErrorNotification={showErrorNotification}
              />
            </li>
          ))}
        </ul>
        <span>
          {`Итого: ${formatter.format(calculateCartTotal() / 100)}`}
        </span>
        <hr />
        <Formik
          initialValues={initFormValue}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            formSubmit(values, resetForm);
          }}
        >
          {({
            values, touched, errors, handleChange
          }) => (
            <Form noValidate autoComplete="off">
              <input
                className={cn({ [styles.invalid]: errors.name && touched.name })}
                type="text"
                id="name"
                name="name"
                placeholder="Ваше имя"
                value={values.name}
                onChange={handleChange}
              />
              <label htmlFor="name">{errors.name}</label>
              <input
                className={cn({ [styles.invalid]: errors.email && touched.email })}
                type="email"
                id="email"
                name="email"
                placeholder="Ваш email"
                value={values.email}
                onChange={handleChange}
              />
              <label htmlFor="email">{errors.email}</label>
              <input
                className={cn({ [styles.invalid]: errors.phone && touched.phone })}
                type="tel"
                id="phone"
                name="phone"
                placeholder="+7 (999) 999-9999"
                pattern="[0-9]*"
                value={values.phone}
                onChange={handleChange}
              />
              <label htmlFor="phone">{errors.phone}</label>
              <div className={cn({ [styles.invalid]: errors.point_id && touched.point_id })}>
                <CustomSelect
                  id="point_id"
                  options={selectOptions}
                  value={values.point_id}
                  onChange={e => handlePointChange(e, handleChange)}
                />
              </div>
              <label htmlFor="point_id">{errors.point_id ? 'Выберите пункт доставки' : ''}</label>
              {/* <p>Предоплата временно недоступна в связи с доработкой сайта. Приносим свои извинения</p> */}
              <p>Нажимая на кнопку "Оплатить", вы соглашаетесь с <a href={PoliticConfidentiality} target="_blank">Политикой конфиденциальности</a> и <a href={PublicOferta} target="_blank">условиями оферты</a>.</p>
              <button disabled={cartCount === 0} type="submit">Оплатить при получении</button>
              <button onClick={() => { handlePaySBP(handleChange) }}>Оплатить по СБП<span>(-{percent}%)</span></button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Cart;

//               <button onClick={() => { handlePay(handleChange) }}>Оплатить картой сейчас<span>(-{percent}%)</span></button>
